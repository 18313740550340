import PageRequest from '../../../../pagination-helper/lib/PageRequest';
import SortingModelT from '../../../../common/models/SortingModelT';
import EnumSortingUsersWithTheirEndpoints from '../../../../analytic-users/i-service/models/EnumSortingUsersWithTheirEndpoints';

export default class UsersWithTheirEndpointsRequest  {
	ProjectLogGroupId?: number;
	FromDate: Date;
	ToDate: Date;
	Page: PageRequest;
	SortingModel: SortingModelT<EnumSortingUsersWithTheirEndpoints>;
	constructor(obj?: Partial<UsersWithTheirEndpointsRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
