import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mt-2 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_tirscript3_table_header_item = _resolveComponent("tirscript3-table-header-item")!
  const _component_tirscript3_table_body_item = _resolveComponent("tirscript3-table-body-item")!
  const _component_tirscript3_table = _resolveComponent("tirscript3-table")!
  const _component_tirscript3_pagination = _resolveComponent("tirscript3-pagination")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: 'Список пользователей',
    selectSource: false
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_filter_component, {
        filter: _ctx.filter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
        selectInterval: false,
        selectLogsFilter: false,
        selectViewFilter: false,
        onFilterData: _ctx.filterData
      }, null, 8, ["filter", "onFilterData"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
      _createVNode(_component_tirscript3_table, {
        items: _ctx.usersWithTheirEndpointsResponse,
        style: {"overflow-x":"auto"},
        "template-columns": "auto auto auto"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_tirscript3_table_header_item, { defaultSort: null }, {
            default: _withCtx(() => [
              _createTextVNode("# ")
            ]),
            _: 1
          }),
          _createVNode(_component_tirscript3_table_header_item, { defaultSort: null }, {
            default: _withCtx(() => [
              _createTextVNode("Кол-во вызванных методов ")
            ]),
            _: 1
          }),
          _createVNode(_component_tirscript3_table_header_item, { defaultSort: null }, {
            default: _withCtx(() => [
              _createTextVNode("Общее время загрузки на сайте ")
            ]),
            _: 1
          })
        ]),
        body: _withCtx((usersWithTheirEndpointsResponse) => [
          _createVNode(_component_tirscript3_table_body_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(usersWithTheirEndpointsResponse.item.UserId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_tirscript3_table_body_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(usersWithTheirEndpointsResponse.item.NumberOfCallsAllMethods), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_tirscript3_table_body_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(usersWithTheirEndpointsResponse.item.WorkingTimeAllMethods), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["items"]),
      _withDirectives(_createVNode(_component_tirscript3_pagination, {
        ref: "pagination",
        count: _ctx.pageResponse.Count,
        size: _ctx.pageResponse.Size,
        class: "mt-2",
        onOnChange: _ctx.changePage
      }, null, 8, ["count", "size", "onOnChange"]), [
        [_vShow, _ctx.pageResponse.Count > 0]
      ]),
      (!_ctx.error.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}